<template>
  <div>
    <v-menu bottom left>
      <template v-slot:activator="{ on }">
        <v-btn outline small v-on="on" >
          <v-icon>more_vert</v-icon>
          <span>Opções</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-tile v-if="account.bank.id == 37 && !account.integration_account_id" @click="wirecardAccountConnect()">
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon color="green">done</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Conectar conta</v-list-tile-title>
        </v-list-tile>
        <v-list-tile v-if="account.bank.id == 37 && account.integration_account_id" @click="disconnectAccount()">
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon color="red">close</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Desconectar conta</v-list-tile-title>
        </v-list-tile>
        <v-divider v-if="account.bank.id == 37"></v-divider>
        <v-list-tile v-if="account.bank.id == 29" @click="$WiEditDialog({ wiConfig: 'finance-accounts-banco-inter', data: account })">
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon color="orange">settings_suggest</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Configurar Integração Banco Inter</v-list-tile-title>
        </v-list-tile>
        <v-list-tile v-if="account.integration_account_data" @click="dialog = true">
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon color="orange">info</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Informações da conta</v-list-tile-title>
        </v-list-tile>
        <v-divider v-if="account.integration_account_data"></v-divider>
        <v-list-tile v-if="$WiCheckPermission('0bae9ce3-400f-8818-94a0-15d1b3bdf088')" link :to="{ name: 'graduations-list', params: { page: 'graduation' }, query: { 'finance_account_id': account.id } }">
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon color="#CCB14D">school</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Projetos de Formatura</v-list-tile-title>
        </v-list-tile>
        <v-divider v-if="$WiCheckPermission('0bae9ce3-400f-8818-94a0-15d1b3bdf088')"></v-divider>
        <v-list-tile link :to="{ name: 'finance-list', params: { page: 'finance-list' }, query: { enable: true, 'finance_account_id': account.id } }">
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon color="blue">receipt</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Movimentação desta conta</v-list-tile-title>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile link :to="{ name: 'finance-list', params: { page: 'finance-in-list' }, query: { enable: true, 'finance_account_id': account.id } }">
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon color="green">get_app</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Receitas desta conta</v-list-tile-title>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile link :to="{ name: 'finance-list', params: { page: 'finance-out-list' }, query: { enable: true, 'finance_account_id': account.id } }">
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon color="red">publish</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Despesas desta conta</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
    <v-dialog v-if="account.integration_account_data" v-model="dialog" width="598" scrollable>
      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>
            <v-icon>account_balance</v-icon>
            <span class="ml-2">Informações da conta</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="height: 300px;">
          <div class="justify-center mb-4">
            <img v-if="account.bank" :src="account.bank.logo_url" :alt="account.bank.name" style="width: 50px;"/>
            <h3>
              <span>{{ account.name }}</span>
              <span v-if="account.bank"> - {{ account.bank.name }}</span>
            </h3>
          </div>
          <code v-if="account.integration_account_data && account.integration_account_data.account" class="justify-left" style="min-width: 100% !important; max-width: 100% !important;">
            {{ account.integration_account_data.account }}
          </code>
          <code v-else class="justify-left" style="min-width: 100% !important; max-width: 100% !important;">
            {{ account.integration_account_data }}
          </code>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { Wirecard } from '@/default/plugins/Wirecard/Wirecard'
  export default {
    name: 'FinanceAccountsMenu',
    data: () => ({
      dialog: false
    }),
    methods: {
      wirecardAccountConnect: function () {
        Wirecard.connect(this.account.id)
      },
      disconnectAccount: function () {
        this.$WiApiPut({
          uri: `finance/account/disconnect/${this.account.id}`,
          data: {},
          config: {
            msgLoad: {
              title: `Desconectando conta...`,
              text: 'Por favor aguarde...'
            },
            msgSucess: {
              title: 'Conta desconectada com sucesso.',
              text: 'Agora a conta não está mais conectada.'
            },
            msgError: {
              title: 'Erro ao desconectar conta',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja desconectar?`,
              text: 'Esta ação não poderá ser desfeita!'
            }
          },
          callback: () => {
            this.$emit('refresh')
          }
        })
      }
    },
    props: {
      account: Object,
      props: Object
    }
  }
</script>