<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center">
      {{props.item.id}}
    </td>
    <td class="justify-center">
      <strong>{{props.item.name}}</strong>
    </td>
    <td class="justify-center">
      <strong style="font-size: 20px;" :style="{color: props.item.balance_current < 0 ? 'red' : ''}">{{props.item.balance_current | money}}</strong>
    </td>
    <td class="justify-center">
      <strong style="font-size: 20px;" :style="{color: props.item.balance_foreseen < 0 ? 'red' : ''}">{{props.item.balance_foreseen | money}}</strong>
    </td>
    <td class="justify-center" style="padding: 0px 5px !important;">
      <v-list v-if="props.item && props.item.bank" dense style="padding: 0px !important;" color="transparent">
        <v-list-tile style="padding: 0 5px !important;" color="transparent">
          <v-list-tile-avatar>
            <v-avatar size="40">
              <img :src="props.item.bank.logo_url" :alt="props.item.bank.name">
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>
              <strong>{{ props.item.bank.name | stringlength(12) }}</strong>
            </v-list-tile-title>
            <v-list-tile-sub-title v-if="props.item.integration_account_id">
              <strong style="color: green;">Conectado</strong>
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <div v-else> - - - </div>
    </td>
    <td class="justify-center">
      <FinanceAccountsMenu
        :account="props.item"
        @refresh="config.refresh"
      ></FinanceAccountsMenu>
    </td>
  </tr>
</template>
<script>
  import FinanceAccountsMenu from './AccountsMenu'
  import { Wirecard } from '@/default/plugins/Wirecard/Wirecard'
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'FinanceAccounts',
    methods: {
      wirecardAccountConnect: function () {
        Wirecard.connect(this.props.item.id)
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions,
      FinanceAccountsMenu
    }
  }
</script>
<style scoped>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>
