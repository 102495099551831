export class Wirecard {
  static connect (accountId) {
    const connectFullUrl = this.getConnectFullUrl(accountId)
    console.log(connectFullUrl)
    window.location.href = connectFullUrl
  }
  static auth (accountId, code) {
    const authFullUrl = this.getAuthFullUrl(accountId, code)
    console.log(authFullUrl)
    window.location.href = authFullUrl
  }
  static getConnectFullUrl (accountId) {
    return this.getConnectBaseUrl() + '?' + this.getConnectRedirectUri(accountId) + '&' + this.getConnectResponseType() + '&' + this.getConnectClientId() + '&' + this.getConnectScope() + '&' + this.getReturnUrl()
  }
  static getAuthFullUrl (accountId) {
    return this.getAuthBaseUrl() + '?' + this.getConnectRedirectUri(accountId) + '&' + this.getConnectClientId() + '&' + this.getConnectClientSecret() + '&' + this.getAuthGrantType() + '&' + this.getAuthGrantType()
  }
  static getConnectBaseUrl () {
    return 'https://connect.moip.com.br/oauth/authorize'
  }
  static getAuthBaseUrl () {
    return 'https://connect.moip.com.br/oauth/token'
  }
  static getConnectResponseType () {
    const responseType = 'code'
    return 'response_type=' + responseType
  }
  static getConnectClientId () {
    const clientId = 'APP-DKH22WWNDYYA'
    return 'client_id=' + clientId
  }
  static getConnectClientSecret() {
    const clientId = '0a842df4ffca4fdba811936b409b667b'
    return 'client_secret=' + clientId
  }
  static getConnectScope () {
    const scope = 'RECEIVE_FUNDS,REFUND,MANAGE_ACCOUNT_INFO,RETRIEVE_FINANCIAL_INFO,TRANSFER_FUNDS,DEFINE_PREFERENCES'
    return 'scope=' + scope
  }
  static getAuthGrantType () {
    const grantType = 'authorization_code'
    return 'grant_type=' + grantType
  }
  static getConnectRedirectUri (accountId) {
    const redirectUri = 'https://api.portal.domusformaturas.com/api/v1/moip/permissao?account=' + accountId
    return 'redirect_uri=' + redirectUri
  }
  static getReturnUrl () {
    return 'return_url=' + btoa(window.location.href)
  }
}